import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AuthNoticeService } from '../../../../core/auth';
import firebase from 'firebase';

@Component({
  selector: 'kt-login',
  templateUrl: './login.component.html',
  encapsulation: ViewEncapsulation.None
})
export class LoginComponent implements OnInit, OnDestroy {
  public form: FormGroup;
  public loading = false;

  constructor(
    private router: Router,
    private authNoticeService: AuthNoticeService,
    private translate: TranslateService,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private fireAuth: AngularFireAuth,
    private changeDetectorRef: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.initializeForm();
  }

  ngOnDestroy(): void {
    this.authNoticeService.setNotice(null);
    this.loading = false;
  }

  initializeForm(): void {
    this.form = this.formBuilder.group({
      email: [
        '',
        Validators.compose([
          Validators.required,
          Validators.email,
          Validators.minLength(3),
          Validators.maxLength(320)
        ])
      ],
      password: [
        '',
        Validators.compose([
          Validators.required,
          Validators.minLength(3),
          Validators.maxLength(100)
        ])
      ]
    });
  }

  signInWithEmailAndPassword(): void {
    this.authNoticeService.setNotice(null);
    const controls = this.form.controls;

    if (this.form.invalid) {
      Object.keys(controls).forEach((controlName) => controls[controlName].markAsTouched());
      return;
    }

    this.loading = true;

    const authData = {
      email: controls.email.value,
      password: controls.password.value
    };

    this.fireAuth
      .signInWithEmailAndPassword(authData.email, authData.password)
      .then(() => {
        this.loading = false;
        this.changeDetectorRef.markForCheck();
      })
      .catch((error) => {
        this.authNoticeService.setNotice(
          this.translate.instant('auth.validation.invalid_login'),
          'danger'
        );
      });
  }

  signInWithGoogle(): void {
    this.authNoticeService.setNotice(null);
    this.fireAuth.signInWithPopup(new firebase.auth.GoogleAuthProvider()).catch((error) => {
      this.authNoticeService.setNotice(
        this.translate.instant('auth.validation.invalid_login'),
        'danger'
      );
    });
  }

  signInWithApple(): void {
    this.authNoticeService.setNotice('Login Apple está indisponível no momento', 'danger');
  }

  hasError(controlName: string, validationType: string): boolean {
    const control = this.form.controls[controlName];
    return control.hasError(validationType);
  }
}
