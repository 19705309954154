export const environment = {
  production: true,
  apiUrl: 'https://api.beta.77app.com.br',
  firebase: {
    apiKey: 'AIzaSyDsopUpzSK5a44qluDP3QwPLfBvC85KJuo',
    authDomain: 'a77app-beta.firebaseapp.com',
    databaseURL: 'https://a77app-beta.firebaseio.com',
    projectId: 'a77app-beta',
    storageBucket: 'a77app-beta.appspot.com',
    messagingSenderId: '1062196384434',
    appId: '1:1062196384434:web:468b13df2a2a1a206eefab'
  }
};
