import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UrlInterceptor implements HttpInterceptor {
  constructor() {}

  public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const baseUrl = request.headers.get('X-Base-Url');
    const appendUrl = !baseUrl || baseUrl === 'true';

    if (appendUrl) {
      return next.handle(
        request.clone({
          url: `${environment.apiUrl}${request.url}`
        })
      );
    }

    return next.handle(request);
  }
}
