import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { LayoutConfigService, TranslationService } from './core/base/layout';
import { locale as enLang } from './core/config/i18n/en';
import { locale as ptLang } from './core/config/i18n/es';
import { locale as jpLang } from './core/config/i18n/pt';
import { AuthService } from './core/auth';
import { Authentication } from './core/auth/auth.model';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'body[kt-root]',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppComponent implements OnInit, OnDestroy {
  // Public properties
  public title = '77App';
  public loader: boolean;
  private unsubscribe: Subscription[] = [];

  authentication$: Observable<Authentication>;

  constructor(
    private auth: AuthService,
    private translationService: TranslationService,
    private layoutConfigService: LayoutConfigService,
    private router: Router
  ) {
    this.translationService.loadTranslations(enLang, jpLang, ptLang);
    this.authentication$ = this.auth.authentication$;
  }

  ngOnInit(): void {
    this.loader = this.layoutConfigService.getConfig('loader.enabled');

    const routerSubscription = this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        window.scrollTo(0, 0);

        setTimeout(() => {
          document.body.classList.add('kt-page--loaded');
        }, 500);
      }
    });

    this.unsubscribe.push(routerSubscription);
  }

  ngOnDestroy(): void {
    this.unsubscribe.forEach((sb) => sb.unsubscribe());
  }
}
