import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Account, EmptyAccount } from './account.model';

@Injectable({ providedIn: 'root' })
export class AccountService {
  constructor(private http: HttpClient) {}

  /**
   * Verifies if the user is logged in returning the
   * user's account if true.
   */
  verify(): Observable<Account | EmptyAccount> {
    return this.http
      .get<Account | EmptyAccount>('/accounts.verify', {
        headers: new HttpHeaders().set('X-Error-Toast', 'false')
      })
      .pipe(catchError(() => of({})));
  }
}
