import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoggedInGuard } from './core/auth';
import { BaseComponent } from './views/theme/base/base.component';
import { ErrorPageComponent } from './views/theme/content/error-page/error-page.component';

const routes: Routes = [
  {
    path: 'auth',
    loadChildren: () => import('app/views/pages/auth/auth.module').then((m) => m.AuthModule)
  },
  {
    path: 'registrations',
    canActivate: [LoggedInGuard],
    loadChildren: () =>
      import('app/views/pages/registration/registration.module').then((m) => m.RegistrationModule)
  },
  {
    path: '',
    component: BaseComponent,
    canActivate: [LoggedInGuard],
    children: [
      {
        path: 'dashboard',
        loadChildren: () =>
          import('app/views/pages/dashboard/dashboard.module').then((m) => m.DashboardModule)
      },
      {
        path: 'iam',
        loadChildren: () => import('app/views/pages/iam/iam.module').then((m) => m.IamModule)
      },
      {
        path: 'organizations',
        loadChildren: () =>
          import('app/views/pages/organization/organization.module').then(
            (m) => m.OrganizationModule
          )
      },
      {
        path: 'finances',
        loadChildren: () =>
          import('app/views/pages/finance/finance.module').then((m) => m.FinanceModule)
      },
      {
        path: 'error/403',
        component: ErrorPageComponent,
        data: {
          type: 'error-v6',
          code: 403,
          title: '403... Access forbidden',
          desc: `Looks like you don't have permission to access for requested page.<br> Please, contact administrator`
        }
      },
      { path: 'error/:type', component: ErrorPageComponent },
      { path: '', redirectTo: 'dashboard', pathMatch: 'full' },
      { path: '**', redirectTo: 'dashboard', pathMatch: 'full' }
    ]
  },

  { path: '**', redirectTo: 'error/403', pathMatch: 'full' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule {}
