export class PageConfig {
  public defaults: any = {
    dashboard: {
      page: {
        title: 'Dashboard',
        desc: 'Últimas atualizações e gráficos'
      }
    },
    finances: {
      receivables: {
        page: {
          title: 'Recebíveis',
          desc: 'Manutenção de recebíveis'
        }
      }
    },
    iam: {
      users: {
        page: {
          title: 'Usuários',
          desc: 'Manutenção de usuários'
        }
      }
    },
    organizations: {
      companies: {
        page: {
          title: 'Empresas',
          desc: 'Manutenção de empresas'
        }
      },
      branches: {
        page: {
          title: 'Filiais',
          desc: 'Manutenção de filiais'
        }
      },
      'cost-centers': {
        page: {
          title: 'Centros de custo',
          desc: 'Manutenção de centros de custo'
        }
      }
    }
  };

  public get configs(): any {
    return this.defaults;
  }
}
