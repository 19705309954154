import { Component } from '@angular/core';
import { ActivityService } from './activity.service';

@Component({
  selector: 'a77-activity',
  templateUrl: './activity.component.html',
  styleUrls: ['./activity.component.scss']
})
export class ActivityComponent {
  constructor(public activity: ActivityService) {}
}
