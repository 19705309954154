import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { NgxPermissionsService } from 'ngx-permissions';
import * as objectPath from 'object-path';
import { Observable, Subscription } from 'rxjs';
import {
  LayoutConfigService,
  MenuConfigService,
  PageConfigService
} from '../../../core/base/layout';
import { LayoutConfig } from '../../../core/config/layout.config';
import { MenuConfig } from '../../../core/config/menu.config';
import { PageConfig } from '../../../core/config/page.config';
import { HtmlClassService } from '../html-class.service';
import { AuthService } from '../../../core/auth';
import { Authentication } from '../../../core/auth/auth.model';

@Component({
  selector: 'kt-base',
  templateUrl: './base.component.html',
  styleUrls: ['./base.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class BaseComponent implements OnInit, OnDestroy {
  // Public variables
  selfLayout: string;
  asideDisplay: boolean;
  asideSecondary: boolean;
  subheaderDisplay: boolean;
  fluid: boolean;

  // Private properties
  private unsubscribe: Subscription[] = []; // Private properties
  private authentication$: Observable<Authentication>;

  constructor(
    private layoutConfigService: LayoutConfigService,
    private menuConfigService: MenuConfigService,
    private pageConfigService: PageConfigService,
    private htmlClassService: HtmlClassService,
    private permissionsService: NgxPermissionsService,
    private authService: AuthService
  ) {
    this.loadRoles();

    // register configs by demos
    this.layoutConfigService.loadConfigs(new LayoutConfig().configs);
    this.menuConfigService.loadConfigs(new MenuConfig().configs);
    this.pageConfigService.loadConfigs(new PageConfig().configs);

    // setup element classes
    this.htmlClassService.setConfig(this.layoutConfigService.getConfig());

    const subscription = this.layoutConfigService.onConfigUpdated$.subscribe((layoutConfig) => {
      // reset body class based on global and page level layout config, refer to html-class.service.ts
      document.body.className = '';
      this.htmlClassService.setConfig(layoutConfig);
    });
    this.unsubscribe.push(subscription);
  }

  ngOnInit(): void {
    const config = this.layoutConfigService.getConfig();
    this.selfLayout = objectPath.get(config, 'self.layout');
    this.asideDisplay = objectPath.get(config, 'aside.self.display');
    this.asideSecondary = objectPath.get(config, 'aside-secondary.self.display');
    this.subheaderDisplay = objectPath.get(config, 'subheader.display');
    this.fluid = objectPath.get(config, 'content.width') === 'fluid';

    // let the layout type change
    const subscription = this.layoutConfigService.onConfigUpdated$.subscribe((cfg) => {
      setTimeout(() => {
        this.selfLayout = objectPath.get(cfg, 'self.layout');
      });
    });
    this.unsubscribe.push(subscription);
  }

  ngOnDestroy(): void {
    this.unsubscribe.forEach((subscription) => subscription.unsubscribe());
  }

  loadRoles() {
    this.authentication$ = this.authService.authentication$;

    const subscription = this.authentication$.subscribe((authentication: Authentication) => {
      const { authenticated, principal } = authentication;

      if (authenticated) {
        this.permissionsService.flushPermissions();
        principal.roles.forEach((role: string) => this.permissionsService.addPermission(role));
      }
    });

    this.unsubscribe.push(subscription);
  }
}
