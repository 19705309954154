import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { AuthNoticeService } from '../../../../core/auth';

@Component({
  selector: 'kt-forgot-password',
  templateUrl: './forgot-password.component.html',
  encapsulation: ViewEncapsulation.None
})
export class ForgotPasswordComponent implements OnInit, OnDestroy {
  public form: FormGroup;
  public loading = false;
  private unsubscribe: Subject<any>;

  constructor(
    private authNoticeService: AuthNoticeService,
    private translate: TranslateService,
    private router: Router,
    private fb: FormBuilder,
    private cdr: ChangeDetectorRef,
    private fireAuth: AngularFireAuth
  ) {
    this.unsubscribe = new Subject();
  }

  ngOnInit() {
    this.initializeForm();
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
    this.loading = false;
  }

  initializeForm() {
    this.form = this.fb.group({
      email: [
        '',
        Validators.compose([
          Validators.required,
          Validators.email,
          Validators.minLength(3),
          Validators.maxLength(320)
        ])
      ]
    });
  }

  sendPasswordResetEmail() {
    const controls = this.form.controls;

    if (this.form.invalid) {
      Object.keys(controls).forEach((controlName) => controls[controlName].markAsTouched());

      return;
    }

    this.loading = true;
    const email = controls.email.value;

    this.fireAuth
      .sendPasswordResetEmail(email)
      .then((result) => {
        this.authNoticeService.setNotice(this.translate.instant('auth.forgot.success'), 'success');
        this.router.navigateByUrl('/auth/login');
      })
      .catch(() => {
        this.authNoticeService.setNotice(
          this.translate.instant('auth.validation.not_found', {
            name: this.translate.instant('auth.input.email')
          }),
          'danger'
        );
      })
      .finally(() => {
        this.loading = false;
      });
  }

  hasError(controlName: string, validationType: string): boolean {
    const control = this.form.controls[controlName];
    return control.hasError(validationType) && (control.dirty || control.touched);
  }
}
