export class MenuConfig {
  public defaults: any = {
    header: {
      self: {},
      items: [
        {
          title: 'Dashboard',
          translate: 'menu.dashboard',
          root: true,
          alignment: 'left',
          page: '/dashboard'
        },
        {
          title: 'IAM',
          translate: 'menu.iam.title',
          root: true,
          alignment: 'left',
          toggle: 'click',
          permission: 'OWNER',
          submenu: [
            {
              title: 'Usuários',
              translate: 'menu.iam.user',
              page: '/iam/users'
            }
          ]
        },
        {
          title: 'Organização',
          translate: 'menu.organization.title',
          root: true,
          alignment: 'left',
          toggle: 'click',
          permission: 'OWNER',
          submenu: [
            {
              title: 'Empresas',
              translate: 'menu.organization.company',
              page: '/organizations/companies'
            },
            {
              title: 'Filiais',
              translate: 'menu.organization.branch',
              page: '/organizations/branches'
            },
            {
              title: 'Centros de custo',
              translate: 'menu.organization.cost_center',
              page: '/organizations/cost-centers'
            }
          ]
        },
        {
          title: 'Financeiro',
          translate: 'menu.finance.title',
          root: true,
          alignment: 'left',
          toggle: 'click',
          permission: 'TECHNICIAN',
          submenu: [
            {
              title: 'Recebíveis',
              translate: 'menu.finance.receivable',
              page: '/finances/receivables'
            }
          ]
        }
      ]
    }
  };

  public get configs(): any {
    return this.defaults;
  }
}
