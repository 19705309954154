import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { Principal, Authentication } from './auth.model';
import { Role } from './role.model';
import { BehaviorSubject, Observable, of, zip } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';
import { User } from 'firebase';
import { AccountService } from '../account/account.service';
import { Router } from '@angular/router';
import { AuthNoticeService } from './auth-notice/auth-notice.service';
import { SplashScreenService } from '../base/layout';
import { Account } from '../account/account.model';
import IdTokenResult = firebase.auth.IdTokenResult;
import { startsWith } from 'lodash';

@Injectable({ providedIn: 'root' })
export class AuthService {
  static ALLOWED_ROLES = [Role.OWNER, Role.TECHNICIAN, Role.USER];
  static ALLOWED_STATUSES = ['PENDING', 'FINISHED'];

  private targetPathname: string;
  private authentication: BehaviorSubject<Authentication>;
  public readonly authentication$: Observable<Authentication>;

  constructor(
    private fireAuth: AngularFireAuth,
    private accountService: AccountService,
    private authNoticeService: AuthNoticeService,
    private splashScreenService: SplashScreenService,
    private router: Router
  ) {
    this.targetPathname = window.location.pathname;
    this.authentication = new BehaviorSubject<Authentication>(null);
    this.authentication$ = this.authentication.asObservable();

    this.fireAuth.authState.pipe(this.mergeUser()).subscribe((principal: Principal) => {
      if (this.hasAccess(principal)) {
        if (startsWith(this.targetPathname, '/auth')) {
          this.targetPathname = '/dashboard';
        }

        this.authentication.next({ authenticated: true, principal });
        this.router.navigateByUrl(this.targetPathname);
        this.splashScreenService.hide();
      } else {
        this.authentication.next({ authenticated: false });
        this.splashScreenService.hide();
      }
    });
  }

  private mergeUser() {
    return mergeMap((user: User) => {
      if (user == null) {
        return of({});
      }

      return zip(user.getIdTokenResult(true), this.accountService.verify()).pipe(
        map((results: any[]) => {
          const tokenResult: IdTokenResult = results[0];
          const accountResult: Account = results[1];

          return {
            id: user.uid,
            name: user.displayName || accountResult.name,
            email: user.email,
            emailVerified: user.emailVerified,
            photoUrl: user.photoURL,
            roles: tokenResult.claims.roles || [],
            type: accountResult.type,
            status: accountResult.status
          };
        })
      );
    });
  }

  public getAuthentication(): Authentication {
    return this.authentication.value;
  }

  public logout(): void {
    this.fireAuth.signOut().then(() => {
      this.router.navigateByUrl('/auth/login');
    });
  }

  hasAccess(principal: Principal) {
    return (
      principal &&
      principal.emailVerified &&
      AuthService.ALLOWED_STATUSES.includes(principal.status) &&
      principal.roles.some((role) => AuthService.ALLOWED_ROLES.includes(role))
    );
  }
}
