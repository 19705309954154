import { Injectable } from '@angular/core';
import { debounce } from 'lodash';
import { Subject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class ActivityService {
  public active$ = new Subject<boolean>();
  public visible$ = new Subject<boolean>();

  private readonly visibleDebounced: any;
  private readonly activeDebounced: any;

  constructor() {
    this.visibleDebounced = debounce(() => this.visible$.next(true), 400);
    this.activeDebounced = debounce(() => this.active$.next(true), 200);
  }

  show(): void {
    this.visibleDebounced();
  }

  start(): void {
    this.activeDebounced();
  }

  stop(): void {
    this.visibleDebounced.cancel();
    this.activeDebounced.cancel();
    this.visible$.next(false);
    this.active$.next(false);
  }
}
