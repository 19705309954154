// USA
export const locale = {
  lang: 'pt',
  data: {
    menu: {
      dashboard: 'Dashboard',
      iam: {
        title: 'IAM',
        user: 'Usuários'
      },
      organization: {
        title: 'Organização',
        company: 'Empresas',
        branch: 'Filiais',
        cost_center: 'Centros de custo'
      },
      finance: {
        title: 'Financeiro',
        receivable: 'Recebíveis',
        branch: 'Filiais',
        cost_center: 'Centros de custo'
      }
    },
    auth: {
      general: {
        or: 'OU',
        submit_button: 'Enviar',
        forgot_button: 'Esqueceu sua senha?',
        back_button: 'Voltar'
      },
      login: {
        title: 'Acesse o 77App',
        button: 'Entrar'
      },
      forgot: {
        title: 'Esqueceu a sua senha?'
      },
      input: {
        email: 'Email',
        password: 'Senha'
      },
      validation: {
        required_field: 'Obrigatório',
        min_length_field: 'Tamanho mínimo permitido:',
        max_length_field: 'Tamanho máximo permitido:',
        invalid_field: 'Inválido'
      }
    },

    // 77App
    general: {
      buttons: {
        back: 'Voltar',
        confirm: 'Confirmar'
      }
    },

    list: {
      action: {
        title: 'Ações',
        approve: 'Aprovar',
        decline: 'Indeferir',
        disable: 'Desativar',
        edit: 'Alterar',
        enable: 'Ativar',
        permissions: 'Permissões',
        cost_centers: 'Centros de custo'
      }
    },

    dialog: {
      action: {
        close: 'Fechar',
        confirm: 'Confirmar'
      }
    },

    validation: {
      required: 'Obrigatório',
      invalid: 'Inválido'
    },

    statuses: {
      receivable: {
        pending: 'Pendente',
        paid: 'Pago',
        nf: {
          pending: 'Pendente',
          attached: 'Anexada'
        }
      }
    },

    dashboard: {
      summary: {
        title: 'Chamados atendidos',
        ticket_count: 'Chamados atendidos no período',
        amount: 'Total no período',
        average: 'Ticket médio'
      },
      level_ratio: {
        level_1: 'Suporte básico',
        level_2: 'Suporte avançado',
        level_3: 'Suporte experiente',
        allocated: 'Suporte alocado'
      }
    },

    finances: {
      receivable_list: {
        columns: {
          created_at: 'Criado em',
          total: 'Valor a receber',
          count: 'Chamados',
          status: 'Status',
          nf_status: 'Status NF'
        },
        actions: {
          attach: 'Anexar NF'
        },
        missing: 'Não há recebíveis disponíveis'
      },
      receivable_nf: {
        created_at: 'Criado em',
        total: 'Valor a receber',
        count: 'Chamados',
        status: 'Status',
        nf_status: 'Status NF',
        nf_dropzone: 'Clique ou arraste a sua NF aqui para anexar'
      }
    },

    iam: {
      user: {
        edit: {
          title: 'Usuário'
        },
        list: {
          name: 'Nome',
          email: 'Email',
          missing: 'Não há usuários cadastrados ainda'
        },
        input: {
          name: 'Nome',
          cpf: 'CPF',
          email: 'Email',
          branch: 'Filial',
          company: 'Empresa',
          branch_x_cost_center: 'Filial - Centro de custo'
        },
        permissions: {
          title: 'Permissões',
          companies: 'Empresas',
          branches: 'Filiais',
          cost_centers: 'Centros de custo',
          add_first_item: 'Adicionar primeiro item'
        }
      }
    },
    organization: {
      company: {
        edit: {
          title: 'Empresa'
        },
        input: {
          name: 'Razão social',
          alias: 'Nome fantasia'
        },
        list: {
          name: 'Razão social',
          alias: 'Nome fantasia',
          cnpj: 'CNPJ',
          missing: 'Não há empresas cadastradas ainda'
        }
      },
      branch: {
        edit: {
          title: 'Filial'
        },
        input: {
          company: 'Empresa',
          cnpj: 'CNPJ',
          alias: 'Nome fantasia',
          cep: 'CEP',
          address: 'Endereço',
          number: 'Número',
          neighborhood: 'Bairro',
          city: 'Cidade',
          state: 'Estado',
          cost_center: 'Centro de custo'
        },
        list: {
          alias: 'Nome fantasia',
          cnpj: 'CNPJ',
          missing: 'Não há filiais cadastradas ainda'
        },
        cost_center: {
          title: 'Centros de custo',
          add_first_item: 'Adicionar primeiro item'
        }
      },
      cost_center: {
        edit: {
          title: 'Centro de custo'
        },
        input: {
          name: 'Nome'
        },
        list: {
          name: 'Nome',
          missing: 'Não há centros de custo cadastrados ainda'
        }
      }
    },

    registration: {
      steps: {
        next: 'Avançar'
      },
      company: {
        basic_data: {
          step_label: 'Dados básicos',
          name: 'Razão social',
          alias: 'Nome fantasia',
          cnpj: 'CNPJ',
          phone: 'Telefone'
        },
        address: {
          step_label: 'Endereço',
          cep: 'CEP',
          address: 'Endereço',
          number: 'Número',
          neighborhood: 'Bairro',
          complement: 'Complemento',
          state: 'Estado',
          city: 'Cidade'
        },
        conclusion: {
          step_label: 'Conclusão',
          message:
            'Parabéns! O seu cadastro foi concluído com sucesso. Em breve entraremos em contato para instruí-lo sobre os próximos passos!'
        }
      },
      technician: {
        basic_data: {
          step_label: 'Dados básicos',
          name: 'Nome',
          birthday: 'Data de nascimento',
          cpf: 'CPF',
          phone: 'Telefone',
          rg: 'RG',
          rg_emission_date: 'Data emissão (RG)',
          rg_issuing_body: 'Orgão emissor (RG)',
          gender: 'Sexo',
          marital_status: 'Estado civil',
          mother_name: 'Nome da mãe',
          father_name: 'Nome do pai'
        },
        address: {
          step_label: 'Endereço',
          cep: 'CEP',
          address: 'Endereço',
          number: 'Número',
          neighborhood: 'Bairro',
          complement: 'Complemento',
          state: 'Estado',
          city: 'Cidade'
        },
        conclusion: {
          step_label: 'Conclusão',
          message:
            'Parabéns! O seu cadastro foi concluído com sucesso. Em breve entraremos em contato para instruí-lo sobre os próximos passos!'
        }
      }
    }
  }
};
