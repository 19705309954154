import { HttpErrorResponse, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ErrorInterceptor implements HttpInterceptor {
  constructor(private toasterService: ToastrService) {}

  public intercept(request: HttpRequest<any>, next: HttpHandler): any {
    const errorToastr = request.headers.get('X-Error-Toast');
    const showError = !errorToastr || errorToastr === 'true';

    return next.handle(request).pipe(
      tap(
        (event) => {},
        (error) => {
          if (showError && error instanceof HttpErrorResponse && error.error) {
            if (error.status > 0) {
              this.toasterService.error(error.error.message, 'Oops', {
                closeButton: true
              });
            }
          }
        }
      )
    );
  }
}
