import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Injectable({ providedIn: 'root' })
export class FormsService {
  hasError(form: FormGroup, controlName: string, validationType: string): boolean {
    const control = form.controls[controlName];

    if (!control) {
      return false;
    }

    return control.hasError(validationType) && (control.dirty || control.touched);
  }

  checkInvalid(form: FormGroup): boolean {
    const controls = form.controls;

    if (form.invalid) {
      Object.keys(controls).forEach((controlName) => controls[controlName].markAsTouched());
    }

    return form.invalid;
  }

  checkValid(form: FormGroup): boolean {
    return !this.checkInvalid(form);
  }
}
