import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { takeWhile } from 'rxjs/operators';
import { eq } from 'lodash';
import { AuthService } from './auth.service';
import { Authentication } from './auth.model';

@Injectable()
export class LoggedInGuard implements CanActivate {
  constructor(private auth: AuthService, private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    return new Promise((resolve) => {
      this.auth.authentication$
        .pipe(takeWhile((authentication) => authentication === null))
        .subscribe({
          complete: () => {
            const { authenticated, principal } = this.auth.getAuthentication();

            if (authenticated) {
              if (eq(principal.status, 'PENDING')) {
                let registrationUrl;

                if (principal.type === 'TECHNICIAN') {
                  registrationUrl = '/registrations/technicians/fill';
                } else if (principal.type === 'USER') {
                  registrationUrl = '/registrations/companies/fill';
                }

                if (state.url !== registrationUrl) {
                  this.router.navigateByUrl(registrationUrl);
                }
              }

              resolve(true);
            } else {
              this.router.navigate(['/auth/login']);
              resolve(false);
            }
          }
        });
    });
  }
}
