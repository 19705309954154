import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { ActivityService } from '../../../../views/partials/layout/activity/activity.service';

@Injectable({
  providedIn: 'root'
})
export class ActivityInterceptor implements HttpInterceptor {
  constructor(public activityService: ActivityService) {}

  public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.activityService.start();

    const activityIndicator = request.headers.get('X-Activity-Indicator');
    const showIndicator = !activityIndicator || activityIndicator === 'true';

    if (showIndicator) {
      this.activityService.show();
    }

    return next.handle(request).pipe(finalize(() => this.activityService.stop()));
  }
}
