// Angular
import { Pipe, PipeTransform } from '@angular/core';
import { formatCPF } from '@brazilian-utils/brazilian-utils';

/**
 * Returns only first letter of string
 */
@Pipe({
  name: 'cpf'
})
export class CpfPipe implements PipeTransform {
  /**
   * Transform
   *
   * @param value: any
   * @param args: any
   */
  transform(value: any, args?: any): any {
    return formatCPF(value);
  }
}
