import { Component, Input, OnInit } from '@angular/core';
import { isEmpty } from 'lodash';
import { Observable } from 'rxjs';
import { AuthService, Principal } from '../../../../../core/auth';
import { Authentication } from '../../../../../core/auth/auth.model';

@Component({
  selector: 'kt-user-profile2',
  templateUrl: './user-profile2.component.html'
})
export class UserProfile2Component implements OnInit {
  authentication$: Observable<Authentication>;

  @Input() avatar = true;
  @Input() greeting = true;
  @Input() badge: boolean;
  @Input() icon: boolean;

  constructor(private authService: AuthService) {}

  ngOnInit(): void {
    this.authentication$ = this.authService.authentication$;
  }

  signOut() {
    this.authService.logout();
  }

  hasPhoto(principal: Principal) {
    return isEmpty(principal.photoUrl);
  }
}
