import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { LayoutConfigService, TranslationService } from '../../../core/base/layout';

@Component({
  selector: 'kt-auth',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AuthComponent implements OnInit {
  public today: number = Date.now();
  public headerLogo: string;

  constructor(
    private translationService: TranslationService,
    private layoutConfigService: LayoutConfigService
  ) {}

  ngOnInit(): void {
    this.translationService.setLanguage(this.translationService.getSelectedLanguage());

    this.headerLogo = this.layoutConfigService.getLogo();
  }
}
